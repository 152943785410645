<template>
  <div>
    <h6 style="padding-top: 15px" class="section">{{ eventSeriesModule.title }}
      - <a v-if="eventSeriesModule.productUrl.length > 0" :href="eventSeriesModule.productUrl" target="_blank">
        <i class="bi bi-link-45deg">Inhalt und weitere Veranstaltungsdetails</i>
      </a>
    </h6>
    <span class="badge bg-warning">{{ eventSeriesModule.priority }}</span>
    <span v-for="badge in eventSeriesModule.badges"
          :key="badge.position"
          :class="['badge', badge.styleClass]">
      {{ badge.text }}
    </span>
    <div class="row" style="padding-top: 2px">
      <div class="col-auto">
        <div class="dropdown">
          <select class="form-select border border-dark text-red" v-model="selectedOption"
                  @change="updateSelectedOption">
            <option disabled value="">Bitte Termin auswählen</option>
            <option v-for="moduleDate in eventSeriesModule.eventSeriesModules" :key="moduleDate.id" :value="moduleDate">
              {{ moduleDate.beginLocation }} - {{ moduleDate.lessons }} UE
              ({{ getOptionSuffix(moduleDate.lessons, moduleDate.dates.length) }}) - Start:
              {{ moduleDate.beginDateTimeString }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-auto">
        <button class="btn btn-primary-transparent text-red border border-dark" @click="openModal">Modul
          buchen
        </button>
        <module-booking-dialog-component :seriesBookingId="selectedOption.seriesBookingId" :module="eventSeriesModule"
                                         :option="moduleDetails" v-if="isModalOpen" @close="closeModal"/>
      </div>
    </div>
    <hr/>
  </div>
</template>
<script>
import ModuleBookingDialogComponent from "@/components/ModuleBookingDialogComponent.vue";

export default {
  inject: ['sematoClient'],
  watch: {
    eventSeriesModule: {
      handler(newVal) {
        // Update selectedOption whenever eventSeriesModule changes
        if (newVal && newVal.eventSeriesModules && newVal.eventSeriesModules.length) {
          this.selectedOption = newVal.eventSeriesModules[0];
        } else {
          this.selectedOption = null;
        }
      },
      immediate: true // Call the handler immediately to initialize selectedOption
    }
  },
  components: {
    ModuleBookingDialogComponent
  },
  data() {
    return {
      selectedOption: null,
      isModalOpen: false,
      moduleDetails: null
    };
  },
  props: ['eventSeriesModule', 'seriesBookingId'],
  mounted() {
    // Set the first item from eventSeriesModules as the default selection
    this.selectedOption = this.eventSeriesModule.eventSeriesModules[0];
  },
  methods: {
    handleOptionClick(option) {
      this.selectedOption = option;
    },
    getOptionSuffix(lessons, numEventDays) {
      if (lessons === 4 && numEventDays === 1) {
        return "kompakt";
      } else if ((lessons > 4 && lessons <= 8) && numEventDays === 1) {
        return "ganztägig";
      } else if (numEventDays > 1) {
        return numEventDays + "-tägig";
      }
    },
    loadModule(moduleId) {
      this.sematoClient.get(`event/${moduleId}`)
          .then(response => {
            this.moduleDetails = response.data
            this.isModalOpen = true;
          })
    },
    openModal() {
      this.loadModule(this.selectedOption.id)
    },
    closeModal() {
      this.isModalOpen = false;
    }
  }
}
</script>

<style scoped>
.btn-primary-transparent {
  background-color: transparent;
  border-color: transparent;
  color: #E30613; /* Red text color */
}

.text-red {
  color: #E30613; /* Red text color */
}
</style>