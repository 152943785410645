<template>
  <h6>{{ event.details[0].subtitle }}</h6>
  <div>
    <span class="badge bg-primary" style="margin-right: 10px;">{{ event.details[0].contentType }}</span>
    <span class="badge bg-primary" style="margin-right: 10px;">{{ event.details[0].distributionType }}</span>
    <span class="badge bg-primary" style="margin-right: 10px;">{{ event.details[0].lessons }} UE</span>
    <span class="badge bg-primary" style="margin-right: 10px;">{{ event.priority }}</span>
    <span v-for="badge in event.details[0].badges"
          :key="badge.position"
          :class="['badge', badge.styleClass]">
      {{ badge.text }}&nbsp;
    </span>
  </div>
  <hr/>
  <div class="mb-3"></div>
  <p v-if="event.details[0].notifications">{{ event.details[0].notifications }}</p>

  <div class="row justify-content-start">
    <div class="col-12">
      <div v-for="date in upcomingDates(event)" v-bind:key="date.time">
        <div class="d-flex flex-wrap align-items-center">
          <div v-if="date.eventDayStatus === 'CANCELLED'">
            <s>
              <p class="date">{{ date.dateString ? date.dateString : date.startTimeString }}</p>
            </s>
          </div>
          <div v-else-if="date.eventDayStatus !== 'CANCELLED'">
            <p class="date">
              {{ date.dateString ? date.dateString : date.startTimeString }} <i>{{ date.description }}</i></p>
            <div v-if="!past && !event.waitinglist && !isCancelled(event)">
              <!-- Flexbox container for button and fieldset -->
              <div style="display: flex; flex-direction: column; align-items: flex-start;">
                <!-- Button -->
                <b-button
                    v-if="date.webinar && !past && !event.waitinglist && !isCancelled(event) && date.webinarUrl !== ''"
                    variant="link"
                    class="openBtn"
                    v-on:click="openWebinar(event.id, date.webinarUrl)"
                    style="margin-bottom: 8px;"
                >
                  Onlinemodul via Zoom beitreten
                </b-button>

                <b-button
                    v-if="date.zoomAccount"
                    variant="link"
                    class="openBtn"
                    v-on:click=toggleCredentials
                >
                  Zugangsdaten anzeigen
                </b-button>
                <div v-if="showCredentials" style="padding-left: 8px;">
                  <p>Nutzer: {{ date.zoomAccount.zoomAccountName }}</p>
                  <p>Kennwort: {{ date.zoomAccount.zoomAccountPassword }}</p>
                </div>
              </div>
            </div>
            <p v-else-if="date.webinar && !past && !event.waitinglist" class="webinar">Online</p>
            <p v-else-if="!past && event.waitinglist" class="webinar">(Warteliste)</p>
            <p v-if="!date.webinar && date.locationString" class="location">{{ date.locationString }}</p>
            <p v-if="date.locationNotification" class="notificaton"><i>{{ date.locationNotification }}</i></p>
          </div>
        </div>
      </div>

      <p v-show="pastDates(event).length > 0" class="mb-2 mt-3">Vergangen:</p>

      <div v-for="date in pastDates(event)" v-bind:key="date.time">
        <div class="d-flex align-items-center">
          <div v-if="date.eventDayStatus === 'CANCELLED'">
            <s>
              <p class="date">{{ date.dateString ? date.dateString : date.startTimeString }}</p>
            </s>
          </div>
          <div v-else-if="date.eventDayStatus !== 'CANCELLED'">
            <p class="date past-date">{{ date.dateString ? date.dateString : date.startTimeString }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12" v-if="event.files !== ''">
      <p class="mb-2 mt-3">Dateien:</p>

      <div v-for="file in event.files" v-bind:key="file">
        <b-button variant="link" class="dlBtn" v-on:click="downloadFile(event.id, file, userType)">
          <i class="bi bi-download"></i>
          {{ file }}
        </b-button>
      </div>
    </div>
    <div>
        <p v-if="!isFinished(event) && !isCancelled(event)" :style="{ color: '#838383' }">Teilnahmebescheinigung (nach Veranstaltungsabschluss)</p>
        <p v-else-if="isFinished(event) && !isTNBInList() && endedAtLeast10DaysAgo()" :style="{ color: '#838383' }">Teilnahmebescheinigung (noch in Vorbereitung)</p>
    </div>
  </div>
</template>

<script>
import {saveAs} from 'file-saver';

export default {
  name: "EventCard",
  inject: ['sematoClient'],
  data: () => {
    return {
      showCredentials: false
    };
  },
  methods: {
    toggleCredentials() {
      this.showCredentials = !this.showCredentials;
    },
    seriesTitle(booking) {
      return booking.seriesId === 0 ? "" : booking.seriesTitle;
    },

    isCancelled(event) {
      return event.status === 'CANCELLED';
    },

    isRescheduled(event) {
      return event.status === 'RESCHEDULING';
    },

    isFinished(event) {
      return event.cancelled === false && this.upcomingDates(event).length === 0;
    },

    upcomingDates(event) {
      return event.dates
          .filter(date => date.endTimestamp >= Date.now())
          .sort((a, b) => (a.endTimestamp < b.endTimestamp ? -1 : 1));
    },

    pastDates(event) {
      return event.dates
          .filter(date => date.endTimestamp < Date.now())
          .sort((a, b) => (a.endTimestamp > b.endTimestamp ? -1 : 1));
    },

    endedAtLeast10DaysAgo() {
      const tenDaysAgo = Date.now() - 10 * 24 * 60 * 60 * 1000;

      // Ensure the event ended within the last 10 days
      return this.event?.dates?.some(date => date.endTimestamp > tenDaysAgo && date.endTimestamp < Date.now()) || false;
    },

    isTNBInList() {
      return this.event.files.includes('Teilnahmebescheinigung');
    },

    openWebinar(eventId, url) {
      window.open(url);
    },

    downloadFile(eventId, fileName, userType) {
      this.sematoClient
          .get(
              `user/${userType}/download?eventId=${eventId}&fileName=/${fileName}&isEventSeries=false`,
              {responseType: 'arraybuffer'}
          )
          .then(response => {
            // Check if Content-Disposition header is present
            const contentDispositionHeader = response.headers['content-disposition'];

            // Extract filename from content-disposition header if present
            const extractedFileName = contentDispositionHeader
                ? contentDispositionHeader.split('filename=')[1].replace(/"/g, '')
                : fileName;
            const blob = new Blob([response.data], {type: 'application/pdf'});

            // Use FileSaver.js to save the blob as a file
            saveAs(blob, extractedFileName);
          });
    }
  },
  props: ["event", "past", "userType"]
};
</script>

<style scoped>
.webinar {
  margin-bottom: 0;
  margin-left: 12px;
  color: grey;
}

.openBtn {
  margin-bottom: 0;
  margin-left: 12px;
  padding: 0;
}

.dlBtn {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0;
}

.date {
  margin-bottom: 0;
  min-width: 170px;
}

.date.past-date {
  color: dimgrey;
}

.location {
  color: grey;
  flex: 0 0 100%; /* flex-grow, flex-shrink, flex-basis */
  margin-bottom: 4px;
}

.notificaton {
  color: grey;
  flex: 0 0 100%; /* flex-grow, flex-shrink, flex-basis */
  margin-bottom: 8px;
}

</style>
